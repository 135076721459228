/*
@media screen and (max-width: 500px) {
    .game-modal {
        width: 90% !important ;
        font-size: medium !important ;
        top :30% !important ;
    }
} */

.game-modal {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    width: 60%;
    margin-right: -50%;
    font-family: Monospace;
    background-color: #353739;
    border-radius: 10px;
    font-size: 16px;
    transform: translate(-50%, -50%);
    color: white;
    padding: 30px;
    position: inherit;
}

@media screen and (max-width: 500px) {
    .game-modal {
        width: 90%;
        font-size: medium;
        top :40%;
    }
}