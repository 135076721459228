.citizen-section-health-bar{
    align-self: center;
}

@media screen and (max-width: 500px) {
    .heart-icon{
        visibility: hidden;
    }

    .item-section-visibility{
        display: none;
    }
}

@media screen and (max-width: 1000px) {
    .citizen-section{
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: aliceblue;
        width: 100%;
        border-radius: 20px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .citizen-section-health {
        display: inline-block;
        text-align: left;
        width: 70px;    
    }

    .info-icon{
        visibility: hidden;
    }
    .citizen-section-item-text{
        color:gray;
        width:110px;
        align-self: end;
    }    
}

@media screen and (min-width: 1000px) {
    .citizen-section{
        padding-top: 20px;
        padding-bottom: 20px;
        /* background-color: aliceblue; */
        width: 1162px;
        left: calc((100vw - 1162px)/2);;
        border-radius: 20px;
        margin: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
    .citizen-section-health {
        display: inline-block;
        text-align: left;
        width: 200px;    
    }    
    .citizen-section-item-text{
        color:gray;
        width:200px;
        align-self: center;
    }    
}

.citizen-section-image {
  width: 16px;
  height: 18px;
  transform: scale(2,2);
  align-self: center;
}
  
.selected{
    /* border: 3px solid#a4d4ff; */
    background: aliceblue;
}

.citizen-section-item{
    color:gray;
    width:100px;
}

.feedby{
    color:gray
}

.feed-button {
    width:70px;
}

.info-line{
    display: flex;
    align-items: center;
}

.info-icon{
    color: #c9cace;
}

.info-icon:hover{
    color: #797b84;
}