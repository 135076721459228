.citizen-container{
    text-align: -webkit-center;
}

.citien-section-container{
    margin: 10px;
}

.login-message{
    color: gray
}

.mint-button{
    font-size: large;
}

.dashboard-mobile-message{
    color: gray
}

@media screen and (min-width: 1000px) {
    .dashboard-mobile-message{
        display: none;
    }
}