.header-container{
    display: flow-root;
}

@media screen and (min-width: 1000px) {
    .header-button-area{
        justify-content: space-between;
        /* float: right;
        margin: 10px; */
        position: relative;
        left:470px;; 
    }
    .connect-button{
        font-size: large;
    }
    
    .view-account-button{
        font-size: large;
    }
    
    .disconnect-button{
        font-size: large;
    }
    
    .how-to-play-button{
        font-size: large;
    }
    
    .view-map{
        font-size: large;
    }
}

@media screen and (max-width: 1000px){
    .view-map{
        display: none;
    }
};